/* eslint-disable no-console */
import { useEffect } from "react";

const OrigDate = Date;

/* Time-Travel */

// Reset global Date
export function unmockDate() {
  if (!Date["mocked"]) return;
  Date = OrigDate;
}

export function mockDate(offset: number) {
  // Reset mock if offset changed
  if (!!Date["mocked"]) {
    if (offset === Date["offset"]) return;
    unmockDate();
  }

  // Replace global Date w/ Proxy
  Date = new Proxy(OrigDate, {
    // offset new Date()
    construct: function (Target, args: Parameters<DateConstructor>): Date {
      // props to https://stackoverflow.com/a/43160428/671457
      if (args.length === 0) return new Target(OrigDate.now() - offset);
      return new Target(...args);
    },

    get: function (Target, prop, receiver) {
      // used to detect mocked Date
      if (prop === "mocked") return true;
      if (prop === "offset") return offset;

      // offset Date.now()
      if (prop === "now") {
        return () => new Target(OrigDate.now() - offset).getTime();
      }

      return Reflect.get(Target, prop, receiver);
    },
  });
}

export function useAnnounceMount(name: string, ...data: unknown[]): void {
  function getArgs(action: string): unknown[] {
    const args: unknown[] = [`${name} ${action}`];
    if (data) args.push(...data);
    return args;
  }

  useEffect(() => {
    console.log(...getArgs("mounted"));
    return () => console.log(...getArgs("unmounted"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const mapTimes = <T>(times: number, cb: (i: number) => T): T[] => {
  const val: T[] = [];
  for (let i = 0; i < times; i++) val.push(cb(i));
  return val;
};
export const strMapTimes = (times: number, cb: (i: number) => string): string => mapTimes(times, cb).join("");
export const randomInArray = <T>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

const ALPHA = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
const NUM = "0123456789".split("");
const ALPHA_NUM = [...ALPHA, ...NUM];

export const randomAlpha = (count = 1) => strMapTimes(count, () => randomInArray(ALPHA));
export const randomAlphaNum = (count = 1) => strMapTimes(count, () => randomInArray(ALPHA_NUM));

export const fakeGuid = (): string => `${randomAlpha(8)}-${randomAlphaNum(4)}-${randomAlphaNum(4)}-${randomAlphaNum(4)}-${randomAlphaNum(12)}`
