import { Override } from "../types";
import { dateToStr, strToDate } from "../utils/dates";
import { CalendarView, ConnectedAccount as ConnectedAccountDto, ConnectedAccountType as ConnectedAccountTypeDto } from "./client";
import { EasyResponseTypeCaster } from "./domainTypeHelpers";
import { TransformDomain } from "./types";

export enum CalendarAccess {
  None = "NONE",
  SettingsOnly = "SETTINGS_ONLY",
  Read = "READ",
  Write = "WRITE",
  Super = "SUPER",
}

export type ConnectedAccountType = ConnectedAccountTypeDto;

export type ConnectedAccount = Override<
  ConnectedAccountDto,
  {
    readonly id: number;
    readonly type: ConnectedAccountType;
    readonly calendarAccess: CalendarAccess;
    readonly lastSynced?: Date;
    readonly connectedCalendars?: CalendarView[];
  }
>;

export function dtoToConnectedAccount(dto: ConnectedAccountDto): ConnectedAccount {
  return {
    ...dto,
    id: dto.id as number,
    type: dto.type as unknown as ConnectedAccountType,
    calendarAccess: dto.calendarAccess as unknown as CalendarAccess,
    lastSynced: strToDate(dto.lastSynced),
  };
}

export function connectedAccountToDto(data: Partial<ConnectedAccount>): Partial<ConnectedAccountDto> {
  return {
    ...data,
    type: data.type as unknown as ConnectedAccountDto["type"],
    calendarAccess: data.calendarAccess as unknown as ConnectedAccountDto["calendarAccess"],
    lastSynced: dateToStr(data.lastSynced),
  };
}

export class AccountsDomain extends TransformDomain<ConnectedAccount, ConnectedAccountDto> {
  resource = "ConnectedAccount";
  cacheKey = "connectedAccount";
  pk = "id";

  public serialize = connectedAccountToDto;
  public deserialize = dtoToConnectedAccount;

  list = this.manageErrors(this.deserializeResponse(this.api.accounts.list as EasyResponseTypeCaster<ConnectedAccountDto[]>));

  main = this.manageErrors(this.deserializeResponse(this.api.accounts.main));

  delete = this.manageErrors((id: number) => this.api.accounts.delete(id));

  authRedirect = (baseUri: string, state: Record<string, unknown> = {}) => {
    const authUrl = new URL(baseUri, window.location.href);
    authUrl.searchParams.append("state", JSON.stringify(state));
    window.location.href = authUrl.toString();
  };
}
